import { configurationService } from "@/business/configurationService";
import { CHART_DATASET_TEMPLATE, CHART_TEMPLATE, MAP_POINT_TEMPLATE, MAP_TEMPLATE, TABLE_DATA_TEMPLATE, TABLE_HEADERS } from "@/constants/charge-detail.constant";
import { reportsColors } from "@/constants/constants";
import i18n from "@/i18n";
import store from "@/store/store";
import { filterRulesMapped } from "@/tools/filterRules";
import { getGoogleMapsURL, parseTimestamp, speedToStringTranslated } from "@/tools/functions";
import { unitParser, ValueText } from '@colven/common-domain-lib/lib';
import { clone } from "ramda";

/**
 * Obtener los datos para el detalle
 * @param {*} data
 * @param {*} thing
 * @returns
 */
const getDetail = async (data, thing) => {
	return await processData(data, thing);
};

/**
 * Procesa los datos para el componente
 * @param {*} data
 * @param {*} thing
 * @returns
 */
const processData = async (data, thing) => {
	// data = groupFieldByDistance(data);
	const result = {
		charts: [],
		maps: [],
		table: {
			itemKey: "number",
			headers: [],
			filterRules: {},
			data: []
		},
		initPosition: {
			lat: 0,
			long: 0,
			zoom: 2.5
		}
	};

	const mapConfig = await configurationService.get("chargeDetails.map");
	if (mapConfig) {
		result.initPosition.currentLayer = mapConfig.data.currentLayer;
	}

	const chart = clone(CHART_TEMPLATE);
	chart.options.scales.yAxes.forEach(element => {
		element.scaleLabel.labelString = unitParser.changeUnit(element.scaleLabel.labelString, unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) })
	});
	chart.id = "chargeDetailChart" + (Math.random() + 1).toString(36).substring(7);
	const chartDataset = clone(CHART_DATASET_TEMPLATE);
	chartDataset.label = unitParser.changeUnit(i18n.t('chargeDetail.chart.yLabel'), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });
	const map = clone(MAP_TEMPLATE);
	map.id = (Math.random() + 1);
	data.forEach((element, index) => {
		// TABLA
		const newElement = clone(TABLE_DATA_TEMPLATE);

		// ID numérico para identificar a la fila
		newElement.number = index + 1;

		// nombre del equipo
		newElement.nameThing = thing.name;

		// tipo de servicio
		newElement.serviceType = thing.serviceType;

		// fecha y hora de posición (evento)
		newElement.positionTimestamp = new ValueText(
			element.trackTimestampEnd,
			parseTimestamp(element.trackTimestampEnd)
		);

		// fecha y hora de comunicación
		newElement.communicationTimestamp = new ValueText(
			element.comunicationTimestampEnd,
			parseTimestamp(element.comunicationTimestampEnd)
		);

		// cantidad cargada (litros)
		const totalCharged = element.totalCharged;
		const totalChargedNumber = Number(unitParser.parseVolumen(totalCharged, store.getters['user/getInternationalization'].unit, false));
		newElement.chargeAmount = new ValueText(totalChargedNumber, unitParser.parseVolumen(totalCharged, store.getters['user/getInternationalization'].unit, true));

		//referencia correspondiente en el mapa
		newElement.referenceName = getReference(totalChargedNumber);

		// odómetro
		if (element.odometerEnd != null) {
			const odometer = Number(unitParser.parseDistance(element.odometerEnd, store.getters['user/getInternationalization'].unit, false)).toFixed(2);
			newElement.odometer = new ValueText(odometer, unitParser.parseDistance(odometer, store.getters['user/getInternationalization'].unit, true));
		}

		if (element.hourmeterEnd) {
			const hourmeter = Number(element.hourmeterEnd).toFixed(2);
			newElement.hourmeter = new ValueText(hourmeter, `${hourmeter} s`);
		}

		// estado del motor (encendido - apagado)
		if (element.engineRunningEnd != null) {
			const engineStateTranslation = i18n.t(
				`table.data.engineState.${element.engineRunningEnd ? "ON" : "OFF"}`
			);
			newElement.engineState = new ValueText(
				element.engineRunningEnd,
				engineStateTranslation
			);
			newElement.engineStateColor = element.engineRunningEnd
				? "#4CAF50"
				: "#263238";
		}

		// velocidad
		if (element.speedEnd != null) {
			newElement.speed = new ValueText(
				element.speedEnd,
				speedToStringTranslated(element.speedEnd, element.directionEnd)
			);
		}

		const globalVolume = Number(unitParser.parseVolumen(element.fuelLevelEnd, store.getters['user/getInternationalization'].unit, false));
		// volumen global después de la descarga
		newElement.globalVolume = new ValueText(
			globalVolume, unitParser.parseVolumen(element.fuelLevelEnd, store.getters['user/getInternationalization'].unit, true)
		);

		// tanque
		let chartToolTip = [
			i18n.t(`chargeDetail.chart.yLabel`) + ": " + unitParser.parseVolumen(element.totalCharged, store.getters['user/getInternationalization'].unit, true)
		];
		// let tank = i18n.t(`table.data.tank.${element.tankKey}`) + ": " + element.tanks[element.tankKey] + " L";
		let tableChargedTanks = '';
		//ESTO ES PARA AGREGARLE AL TOOLTIP LA CARGA POR TANQUE, queda comentado por ahora.
		/*for (const tankElement in element.tanksCharged) {
			if (element.tanksCharged[tankElement]) {
				const label = i18n.t(`table.data.tank.${tankElement}`) + ": " + unitParser.parseVolumen(element.tanksCharged[tankElement], store.getters['user/getInternationalization'].unit, true);
				tableChargedTanks += label + "\n";
				// tank += "\n" + label;
				chartToolTip.push(label);
			}
		}*/
		newElement.chargedTank = tableChargedTanks;

		// dirección (georefernce)
		newElement.geoReferenceEnd = element.geoReferenceEnd;

		// latitud y longitud
		newElement.lat = element.latEnd;
		newElement.lng = element.longEnd;

		result.table.data.push(newElement);

		// GRÁFICO
		chartDataset.data.push(newElement.chargeAmount.value);
		chart.data.labels.push(newElement.positionTimestamp.text);
		chartDataset.formattedTooltipData.label.push(chartToolTip);
		const color = (index % 2 !== 0) ? reportsColors.CHARGES_EVEN : reportsColors.CHARGES_ODD;
		chartDataset.backgroundColor.push(color);

		//<script> document.querySelector(\".filterMapPopup\").onclick = ()=>{console.log(filterMap);filterMap(marker.businessData.number)}; document.querySelector(\".googleLink\").onclick = function(){window.open(\"https://www.google.com/maps/search/?api=1&query=${charge.latEnd}%2C${charge.longEnd}\")};</script>

		// MAPA
		const popup = `
        <h4>${newElement.nameThing}</h4>
        <hr>${unitParser.changeUnit(i18n.t("chargeDetail.map.popup.litersCharged"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) })}: ${newElement.chargeAmount.text
			}<br>
        ${i18n.t("chargeDetail.map.popup.dateTime")}: ${newElement.positionTimestamp.text
			}<br>
			${i18n.t("chargeDetail.map.popup.georeference")}: ${newElement.geoReferenceEnd
			}<br>
        <a href="https://www.google.com/maps/search/?api=1&query=${element.latEnd}%2C${element.longEnd}"
			class="googleLink" target="_blank">${i18n.t(
				"chargeDetail.seeOnGoogle"
			)}</a>`;
		const point = clone(MAP_POINT_TEMPLATE);
		point.id = newElement.number;
		point.lat = element.latEnd;
		point.lng = element.longEnd;
		point.value = unitParser.parseVolumen(totalCharged, store.getters['user/getInternationalization'].unit, false);
		point.marker.label = `<span style="width:100%;height:100%;">${newElement.nameThing} - ${newElement.chargeAmount.text} - ${newElement.positionTimestamp.text}</span>`;
		point.marker.businessData = {
			number: index + 1,
			hasLinks: true,
			popup
		};
		point.marker.businessData.number = index + 1;
		map.groups[0].series[0].points.push(point);
		result.initPosition.lat = element.latEnd;
		result.initPosition.long = element.longEnd;
	});

	result.table.headers = TABLE_HEADERS;
	result.table.headers.forEach(header => {
		result.table.filterRules[header.value] =
			filterRulesMapped[header.filterType];
		if (header.text === i18n.t('table.headers.charge.nameThing'))
			header.text = unitParser.changeUnit(header.text, unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });

	});
	chart.data.datasets.push(chartDataset);
	result.charts = [chart];
	result.maps = [map];

	return result;
};

// function groupFieldByDistance(charges) {
// 	let result = [];
// 	if (charges && charges.length > 0) {
// 		let newCharges = JSON.parse(JSON.stringify(charges));
// 		let lastCharge = null;
// 		for (const charge of newCharges) {
// 			const ShouldGroupCharge = charge.odometerEnd ?
// 				lastCharge && ((parseInt(lastCharge.odometerEnd) + 20) >= parseInt(charge.odometerEnd)) :
// 				lastCharge && ((parseInt(lastCharge.hourmeterEnd) + 1800) >= parseInt(charge.hourmeterEnd))
// 			if (ShouldGroupCharge) {
// 				lastCharge.tanks[charge.tankKey] += charge.totalCharged;
// 				lastCharge.totalCharged += charge.totalCharged || 0;
// 				lastCharge.fuelLevelEnd = charge.fuelLevelEnd;
// 			} else {
// 				lastCharge = charge;
// 				const tanks = {
// 					TANK_1: 0,
// 					TANK_2: 0,
// 					TANK_3: 0,
// 					TANK_4: 0
// 				}
// 				tanks[charge.tankKey] = charge.totalCharged;
// 				charge.tanks = tanks;
// 				result.push(lastCharge);
// 			}
// 		}
// 	}
// 	return result;
// }

/**
 * Obtener la URL para ir a ver un punto en Google Maps
 * @param {*} lat
 * @param {*} long
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const goToGoogleMaps = (lat, long) => {
	const url = getGoogleMapsURL(lat, long);
	window.open(url);
};

/**
 * Obtener el modelo para el componente
 * @param {*} chargeData
 * @returns
 */
const getComponentModel = (chargeData = null) => {
	if (chargeData != null) {
		return {
			data: chargeData.charges ? chargeData.charges : [],
			thing: {
				id: chargeData.thingId,
				name: chargeData.thingName,
				serviceTypeKey: chargeData.serviceTypeKey,
				serviceType: chargeData.serviceType
			}
		};
	} else {
		return {
			data: [],
			thing: {
				id: null,
				name: null,
				serviceTypeKey: null,
				serviceType: null
			}
		};
	}
};

const cleanBreadcrumbSelectors = () => {
	store.dispatch("breadcrumb/setEntitySelectorItems", []);
}

/**
 * Cargar el breadcrumb con el selector de equipos
 * @param {*} data
 * @param {*} selectedItem
 */
const loadBreadcrumbSelector = (data, selectedItem) => {
	store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
		name: i18n.t("equipments"),
		value: "thingId",
		text: "thingName"
	});
	const items = data.map(element => ({
		thingId: element.thingId,
		thingName: element.thingName
	}));
	store.dispatch("breadcrumb/setEntitySelectorItems", items);
	const item = {
		thingId: selectedItem.thingId,
		thingName: selectedItem.thingName
	};
	store.dispatch("breadcrumb/setSelectedItem", item);
};

/**
 * Obtener la referencia correspondiente del mapa según los litros
 */
function getReference(total) {
	if (total < 20) {
		return "10-charge";
	}
	if (total >= 20 && total < 40) {
		return "20-charge";
	}
	if (total >= 40 && total < 60) {
		return "30-charge";
	}
	if (total >= 60 && total < 80) {
		return "40-charge";
	}
	if (total >= 80) {
		return "50-charge";
	}
	return ''
}

export default {
	getDetail,
	getComponentModel,
	goToGoogleMaps,
	loadBreadcrumbSelector,
	cleanBreadcrumbSelectors
};

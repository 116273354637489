import { CellType, ChartType, DataType, FilterType, SerieType } from '@colven/common-domain-lib/lib'
import i18n from "@/i18n"
import { dateSortFunction } from '@/tools/functions'
import { compareNumberSplitString } from '../tools/functions'

export const MAP_POINT_TEMPLATE = {
    id: null,
    lat: null,
    lng: null,
    value: "TRUCK",
    filters: [],
    marker: {
        title: "",
        type: "img",
        ref: "truck-icon.svg",
        size: 40,
        tooltipDirection: "right",
        tooltipPermanent: false,
        tooltipBorderColor: "rgb(0,176,240)",
        businessData: {
            number: 0,
            direction: "northeast",
            stopped: false
        },
    },
}

export const MAP_TEMPLATE = {
    id: 1,
    name: i18n.t('chargeDetail.map.name'),
    groups: [{
        id: 'chargeDetailMapGroup',
        name: i18n.t('chargeDetail.map.groupName'),
        title: i18n.t('chargeDetail.map.groupName'),
        referenceByInterval: true,
        series: [{
            type: SerieType.MARKERS,
            points: []
        }],
        references: [{
            name: '10-charge',
            color: '#a6d96a',
            isInterval: true,
            intervalStart: 0,
            intervalEnd: 20,
        },
        {
            name: '20-charge',
            color: '#ffffbf',
            isInterval: true,
            intervalStart: 20,
            intervalEnd: 40,
        },
        {
            name: '30-charge',
            color: '#fdae61',
            isInterval: true,
            intervalStart: 40,
            intervalEnd: 60,
        },
        {
            name: '40-charge',
            color: '#d7191c',
            isInterval: true,
            intervalStart: 60,
            intervalEnd: 80,
        },
        {
            name: '50-charge',
            color: '#543005',
            isInterval: true,
            intervalStart: 80
        }
        ],
        exclusive: true,
        displayOnStart: true
    }],
    filters: [],
    info: {}
}

export const TABLE_HEADERS = [{
    text: i18n.t('table.headers.charge.nameThing'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'nameThing',
    width: 220
},
{
    text: i18n.t('table.headers.charge.serviceType'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'serviceType',
    width: 220
},
{
    text: i18n.t('table.headers.chargeDetail.eventTimestamp'),
    align: 'left',
    filterType: FilterType.textField,
    sort: (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') },
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'positionTimestamp',
    width: 220
},
{
    text: i18n.t('table.headers.chargeDetail.communicationTimestamp'),
    align: 'left',
    filterType: FilterType.textField,
    sort: (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') },
    selected: true,
    sortable: true,
    type: DataType.string,
    value: i18n.t('communicationTimestamp'),
    width: 220
},
{
    text: i18n.t('table.headers.chargeDetail.amountCharged'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'chargeAmount',
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},
{
    text: i18n.t('table.headers.chargeDetail.odometer'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'odometer',
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},
{
    text: i18n.t('table.headers.chargeDetail.hourMeter'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'hourmeter',
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},
{
    text: i18n.t('table.headers.chargeDetail.engineState'),
    align: 'left',
    filterType: FilterType.multiSelect,
    options: [
        i18n.t('table.data.engineState.OFF'),
        i18n.t('table.data.engineState.ON')
    ],
    selected: true,
    sortable: true,
    type: DataType.boolean,
    cellType: CellType.textBackgroundColor,
    cellConfig: {
        round: true,
        color: 'engineStateColor',
    },
    value: 'engineState',
    width: 170,
},
{
    text: i18n.t('table.headers.chargeDetail.speed'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'speed',
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},
{
    text: i18n.t('table.headers.chargeDetail.globalVolume'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'globalVolume',
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},
/*{
    text: i18n.t('table.headers.chargeDetail.chargedTank'),
    align: 'left',
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'chargedTank',
    width: 220
},*/
{
    text: i18n.t('table.headers.chargeDetail.chargeAddress'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'geoReferenceEnd',
    width: 220
}
]

export const TABLE_DATA_TEMPLATE = {
    number: null,
    nameThing: null,
    serviceType: null,
    positionTimestamp: null,
    communicationTimestamp: null,
    positionTimestampColor: null,
    communicationTimestampColor: null,
    chargeAmount: null,
    odometer: null,
    engineState: null,
    speed: null,
    globalVolume: null,
    chargedTank: null,
    geoReference: null,
    lat: null,
    lng: null
}

export const CHART_TEMPLATE = {
    id: 'chargeDetailChart',
    name: i18n.t('chargeDetail.chart.name'),
    type: ChartType.BAR,
    data: {
        labels: [],
        datasets: []
    },
    options: {
        // TODO: analizar si hay que agregar el rango de tiempo como los gráficos del portal
        title: {
            display: false,
            text: ''
        },
        legend: false,
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: false
                },
                stacked: false,
                ticks: {
                    beginAtZero: true
                },
                scaleLabel: {
                    display: true,
                    labelString: i18n.t('chargeDetail.chart.xLabel')
                },
                /*
                type: 'time',
                time: {
                    parser: 'X',
                    unit: 'minute',
                    tooltipFormat: 'DD-MM-YY HH:mm:ss',
                    displayFormats: {
                        minute: 'DD-MM-YY HH:mm:ss',
                    },
                },
                */
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    display: true
                },
                stacked: false,
                ticks: {
                    beginAtZero: true
                },
                scaleLabel: {
                    display: true,
                    labelString: i18n.t('chargeDetail.chart.yLabel')
                }
            }]
        },
        tooltips: {
            mode: 'x',
            intersect: false,
            callbacks: {
                label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].formattedTooltipData.label[tooltipItem.index];
                }
            }
        },
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy'
                },
                zoom: {
                    enabled: true,
                    mode: 'xy'
                }
            }
        },
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false
    },
    showDatalabels: false
}

export const CHART_DATASET_TEMPLATE = {
    type: undefined,
    label: i18n.t('chargeDetail.chart.yLabel'),
    dataProperty: undefined,
    originalData: [],
    data: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    backgroundColor: [],
    borderWidth: 1,
    fill: true,
    yAxisID: undefined,
    lineTension: 0
}

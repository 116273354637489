import { render, staticRenderFns } from "./ChargeDetailComponent.vue?vue&type=template&id=2b3f8372&scoped=true"
import script from "./ChargeDetailComponent.js?vue&type=script&lang=js&external"
export * from "./ChargeDetailComponent.js?vue&type=script&lang=js&external"
import style0 from "./ChargeDetailComponent.vue?vue&type=style&index=0&id=2b3f8372&prod&lang=css"
import style1 from "./ChargeDetailComponent.vue?vue&type=style&index=1&id=2b3f8372&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b3f8372",
  null
  
)

export default component.exports
import reportStorageApi from '@/api/report-storage.api';
import { breadcrumbService } from "@/business/breadcrumb.service";
import { chargeService } from "@/business/charge.service";
import chargeDetailService from "@/business/chargeDetail.service";
import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
import ChargeDetailComponent from '@/components/charge-detail/ChargeDetailComponent.vue';
import BreadcrumbComponent from "@/components/commons/breadcrumb/BreadcrumbComponent.vue";
import FilterComponent from "@/components/filter/FilterComponent.vue";
import { REPORT_TYPES, SnackbarStyle } from "@/constants/constants";
import i18n from "@/i18n";
import middleware from "@/middleware";
import { mapActions, mapMutations } from 'vuex';
import store from "@/store/store";
import { unitParser } from '@colven/common-domain-lib/lib';

export default {
    components: {
        BreadcrumbComponent,
        ChargeDetailComponent,
        FilterComponent
    },
    data: () => ({
        showFilters: false,
        model: {
            charts: [],
            maps: [],
            table: {
                headers: [],
                data: [],
            },
        },
        middleware: middleware,
        loading: false,
        loadingChart: false,
        reportDetails: "Deatils",
        title: "charges",
        tableDataKey: "number",
        tableFilterRules: {},
        disableRefreshTable: true,
        loadingTable: false,
        getDetailsForTable: null,
        getDetailsForChart: null,
        tableConfig: {},
        // botones de cada fila
        rowActions: [
            {
                id: 1,
                name: i18n.t("detail"),
                color: "blue",
                icon: "mdi-file-document-outline",
            },
        ],
        highlightRow: null,
        // tabs para el breadcrumb
        tabs: [{
            id: 'tab-summary',
            name: i18n.t('chargeSummary.breadcrumbTabSummary')
        }],
        // tab seleccionada
        currentTab: 'tab-summary',
        // botones del breadcrumb
        breadcrumbButtons: [],
        detailKey: REPORT_TYPES.CHARGE_DETAIL,
        detailModel: chargeDetailService.getComponentModel(),
        filters: filterService.getFilterModel(),
        initPosition: null,
        maxQuantityLimit: 10,
        orderFilterWorstBestPropertyName: unitParser.changeUnit(i18n.t("chargeSummary.filterProperties.worstBestProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) }),
        dataForRangeFilter: {
            showRangeData: true,
            minLimit: 0,
            maxLimit: 1000,
            propertyName: unitParser.changeUnit(i18n.t("chargeSummary.filterProperties.rangeProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) })
        },
        selectedReferences: []
    }),
    created() {
        this.breadcrumbButtons.push(
            {
                id: 'setting-btn',
                icon: 'settings',
                show: () => { return true },
                disable: () => { return false },
                function: this.selector.bind(this)
            }
        );
    },
    async mounted() {
        this.loading = true;
        this.resetBreadcrumbSelectors();
        const tableConfiguration = await configurationService.get("chargeSummary.table");
        this.tableConfig = (tableConfiguration && tableConfiguration.data) || {};
        try {
            const reportKey = this.$route.query.key
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            let filters = null;
            let charges = null;
            if (report && report.filters) {
                filters = report.filters;
            } else {
                if (Object.keys(this.$route.params).length !== 0) {
                    filters = this.$route.params;
                    charges = await chargeService.getChargeSummary(filters);
                } else {
                    const configuration = await configurationService.get(REPORT_TYPES.CHARGE_SUMMARY);
                    filters = configuration && configuration.data && configuration.data.filters
                        ? configuration.data.filters
                        : filterService.getFilterModel();
                }
            }
            if (report && !charges) {
                charges = await chargeService.generateReportByData(report.data);
            }
            this.filters = filters;
            if (!charges && this.$route.params && this.$route.params.dateData) {
                this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
            } else {
                this.setReport(charges);
            }
        } finally {
            this.loading = false;
        }
    },
    beforeDestroy() {
        this.closeReportDefer();
    },
    methods: {
        ...mapActions({
            showSnackbar: "snackbar/showSnackbar"
        }),
        ...mapMutations('reportDefer', {
            showReportDefer: 'showReportDefer',
            closeReportDefer: 'closeReportDefer',
            commitVisible: 'commitVisible'
        }),
        async setReportByKey(reportKey) {
            if (reportKey) {
                const report = reportKey && await reportStorageApi.getReport(reportKey);
                let filters = null;
                let charges = null;
                if (report && report.filters) {
                    filters = report.filters;
                } else {
                    if (Object.keys(this.$route.params).length !== 0) {
                        filters = this.$route.params;
                        charges = await chargeService.getChargeSummary(filters);
                    } else {
                        const configuration = await configurationService.get(REPORT_TYPES.CHARGE_SUMMARY);
                        filters = configuration && configuration.data && configuration.data.filters
                            ? configuration.data.filters
                            : filterService.getFilterModel();
                    }
                }
                if (report && !charges) {
                    charges = await chargeService.generateReportByData(report.data);
                }
                this.filters = filters;
                this.setReport(charges);
                if (this.loadingChart) this.commitVisible({ visible: true })
            }
        },
        async setReport(charges) {
            if (charges) {
                this.initPosition = charges.initPosition;
                this.model.charts.cleanAndUpdate(charges.chart);
                this.model.maps.cleanAndUpdate(charges.maps);
                this.model.table.headers.cleanAndUpdate(charges.table.headers);
                this.model.table.data.cleanAndUpdate(charges.table.data);
                breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CHARGE');
                this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "CHARGE");
                this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
                this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "CHARGE");
            } else {
                this.cleanData();
                this.showFilters = true;
            }
        },
        cleanData() {
            this.initPosition = null;
            this.model.charts.cleanAndUpdate([]);
            this.model.maps.cleanAndUpdate([]);
            this.model.table.headers.cleanAndUpdate([]);
            this.model.table.data.cleanAndUpdate([]);
            breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CHARGE');
            this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "CHARGE");
            this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
            this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "CHARGE");
        },
        resetBreadcrumbSelectors() {
            store.dispatch("breadcrumb/setEntitySelectorItems", []);
        },
        async saveFilterChanges() {
            configurationService
                .save(REPORT_TYPES.CHARGE_SUMMARY, { filters: this.filters })
                .then(() => {
                })
                .catch(() => {
                });
            this.closeFilter();
            this.cleanData();
            if (this.currentTab !== "tab-summary") {
                this.tabSelected("tab-summary");
            }
            this.loading = true;
            try {
                const result = await chargeService.getChargeSummary(this.filters);
                if (result) {
                    this.setReport(result);
                } else {
                    this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
                }

            } finally {
                this.loading = false;
            }

        },
        closeFilter() {
            this.showFilters = false;
        },
        disableSelectorButton() {
            this.showFilters = false;
        },
        selector() {
            this.showFilters = !this.showFilters;
        },
        /**
         * Método que recibe los datos de la fila clickeada, obtiene el nro de fila que la identifica y llama a filtrar las cargas en el mapa. También centra el mapa en las cargas.
         * @param {*} rowData
         */
        filterChargesByRowClick(rowData) {
            this.filterCharges(rowData.thingName);
            this.highlightSelectedRow(rowData.number);
            //TODO: esta solución no es la correcta; se debería calcular los centros correspondientes y no tomar uno de los puntos existentes para centrar.
            const lastChargeIndex = rowData.charges.length - 1;
            const lastChargePos = lastChargeIndex >= 0 && rowData.charges[lastChargeIndex] ?
                {
                    lat: rowData.charges[lastChargeIndex].latEnd,
                    lng: rowData.charges[lastChargeIndex].longEnd
                }
                : null;
            if (lastChargePos) {
                this.centerMapAndSetZoom(lastChargePos.lat, lastChargePos.lng, 2.5);
            }
        },
        /**
         * Centrar el mapa y setea el zoom luego de hacer click en una fila de la tabla
         * TO DO: implementar en el componente MapComponent una prop reactiva o modelo y un watch para ejecutar el centrado y así no usar referencias al DOM físico
         * @param {*} selectedItem
         */
        centerMapAndSetZoom(lat, lng, zoom) {
            const mapRef = this.$refs.chargeSummaryReportMap;
            if (mapRef != null && lat != null && lng != null && zoom != null) {
                mapRef.centerMapAndSetZoom([lat, lng], zoom);
            }
        },
        /**
         * En base a la fila seleccionada se obtienen las referencias a filtrar en el mapa
         * @param {*} refname
         */
        filterCharges(refname) {
            const groupRef = [{
                name: this.model.maps[0].groups[0].name,
                refs: [refname]
            }];
            this.selectedReferences.cleanAndUpdate(groupRef);
        },
        refreshTable() {
        },
        saveConfig(data) {
            configurationService.save("chargeSummary.table", data)
                .then(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveSuccess"),
                        timeout: 10000,
                        style: SnackbarStyle.SUCCESS
                    });
                })
                .catch(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveError"),
                        timeout: 10000,
                        style: SnackbarStyle.ERROR
                    });
                });
        },
        // eslint-disable-next-line no-unused-vars
        rowButtonClicked(button, data) {
            switch (button.id) {
                case 1:
                    this.goToDetail(data);
                    break;
                default:
                    console.log("default");
            }
        },
        goToDetail(selectedItem) {
            if (this.tabs.length === 1) {
                this.tabs.push({
                    id: "tab-detail",
                    name: i18n.t("detail")
                });
            }
            chargeDetailService.loadBreadcrumbSelector(this.model.table.data, {
                thingId: selectedItem.thingId,
                thingName: selectedItem.thingName
            });
            this.currentTab = "tab-detail";
            this.changeDetail(selectedItem);
        },
        /**
         * Método para resaltar una fila en la tabla
         * @param {*} param0 
         */
        highlightSelectedRow(number) {
            if (number != null) {
                this.highlightRow = null;
                const that = this;
                this.$nextTick(() => {
                    that.highlightRow = number;
                });
            }
        },
        /**
         * cuando se hace click en un marcador del mapa, se resalta la fila en la tabla
         * @param {*} param0
         */
        onMapClick({ number }) {
            this.highlightSelectedRow(number);
        },
        // eslint-disable-next-line no-unused-vars
        async filtersChanged(data) {
            await configurationService.save("chargeSummary.map", { currentLayer: data.currentLayer });
        },
        /**
         * Acción ejecutada al cambiar de tab (breadcrumb)
         * @param {*} id
         */
        tabSelected(id) {
            this.currentTab = id
            if (this.currentTab === 'tab-summary' && this.tabs.length === 2) {
                store.dispatch("breadcrumb/setEntitySelectorItems", []);
                this.tabs.pop();
            }
        },
        /**
         * Cambia los datos del detalle cuando se selecciona otro item del selector del breadcrumb
         * @param {*} selectedItem
         */
        // eslint-disable-next-line no-unused-vars
        changeDetail(selectedItem) {
            const dataSelected = this.model.table.data.find(element => element.thingId === selectedItem.thingId)
            this.detailModel = chargeDetailService.getComponentModel(dataSelected);
            this.detailModel.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters, null, this.detailModel.thing.name);
        }
    },
};